import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/system';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';

import { OpenMobileNavigation } from '../../../../actions/app';
import { getMobileNavigationOpenState } from '../../../../selectors/app';

import Colors from '../../../../styles/colors';

export const DesktopAppBar = styled(AppBar)(() => ({
    width: '80%',
    boxShadow: 'none',
}));

export const NavLinkPrimary = styled(NavLink)((props) => ({
    root: {
        height: 60,
        justifyContent: 'unset',
        textAlign: 'left',
        borderRadius: 0,
        color: Colors.darkGray,
        fontSize: '1.3rem',
        fontWeight: 600,
        padding: '6px 8px',
        '& svg': {
            width: 20,
            height: 'auto',
            color: Colors.darkGray,
            opacity: 0.5,
            marginRight: 20,
            marginLeft: 10,
        },
        '&:hover': {
            backgroundColor: props.theme.palette.primary.hover,
        },
        '&.active': {
            color: 'red',
            backgroundColor: props.theme.palette.primary.accent,
            borderLeft: `4px solid ${props.theme.palette.primary.main}`,
            '& svg': {
                color: props.theme.palette.primary.main,
                opacity: 1,
                marginLeft: 5,
            },
            '&:hover': {
                color: Colors.darkGray,
                backgroundColor: props.theme.palette.primary.hover,
            },
        },
    },
}));

export const NavLinkSecondary = styled(NavLink)((props) => ({
    root: {
        height: 39,
        width: 187,
        lineHeight: 1.2,
        [props.theme.breakpoints.down('lg')]: {
            width: 'calc(100% - 100px)',
        },
        justifyContent: 'unset',
        textAlign: 'left',
        color: Colors.darkGray,
        fontSize: '1.2rem',
        fontWeight: 600,
        padding: '6px 8px',
        marginLeft: 40,
        borderRadius: 4,
        '& svg': {
            width: 14,
            height: 'auto',
            color: Colors.darkGray,
            opacity: 0.5,
            marginRight: 15,
            marginLeft: 10,
        },
        '&:hover': {
            backgroundColor: props.theme.palette.primary.hover,
        },
        '&.active': {
            color: props.theme.palette.primary.main,
            backgroundColor: props.theme.palette.primary.accent,
            borderLeft: `4px solid ${props.theme.palette.primary.main}`,
            '& svg': {
                color: props.theme.palette.primary.main,
                opacity: 1,
                marginLeft: 5,
            },
            '&:hover': {
                color: Colors.darkGray,
                backgroundColor: props.theme.palette.primary.hover,
            },
        },
    },
}));

export const ButtonLink = forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (
        <NavLinkPrimary
            ref={ref}
            className={({ isActive }) => (
                isActive ? `${className} active` : className
            )}
            {...otherProps}
        />
    );
});
ButtonLink.propTypes = {
    className: PropTypes.string.isRequired,
};

export const ButtonLinkSecondary = forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (
        <NavLinkSecondary
            ref={ref}
            className={({ isActive }) => (
                isActive ? `${className} active` : className
            )}
            {...otherProps}
        />
    );
});
ButtonLinkSecondary.propTypes = {
    className: PropTypes.string.isRequired,
};

export const NavDesktopContainer = ({ children }) => (
    <DesktopAppBar
        component="div"
        position="sticky"
        sx={{
            display: { xs: 'none', lg: 'block' },
        }}
        style={{ textAlign: 'right', height: '100%' }}
    >
        {children}
    </DesktopAppBar>
);
NavDesktopContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const NavMobilContainer = ({ children }) => {
    const dispatch = useDispatch();
    const mobileNavigationOpenState = useSelector(getMobileNavigationOpenState);

    // this shouldn't be needed, but we aren't getting consistant rerender from the selector value, so we set it in state
    const [openState, setOpenState] = useState(mobileNavigationOpenState);
    useEffect(() => {
        setOpenState(mobileNavigationOpenState);
    }, [mobileNavigationOpenState]);
    return (
        <Drawer
            anchor="left"
            hideBackdrop
            elevation={0}
            open={openState}
            onClose={() => dispatch(OpenMobileNavigation(false))}
            sx={{
                display: { xs: 'flex', lg: 'none' },
                width: '100%',
                flexShrink: 0,
                flexDirection: 'column',
                '& .MuiDrawer-paper': {
                    width: '100%',
                    boxSizing: 'border-box',
                },
            }}
            PaperProps={{
                sx: {
                    backgroundColor: Colors.purple1,
                },
            }}
        >
            {children}
        </Drawer>
    );
};
NavMobilContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
