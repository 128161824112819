import React, { useState } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

import Colors from '../../styles/colors';
import { useGACustomEvent, EVENTS } from '../google-analytics';

const StyledSelect = styled(Select)(() => ({
    border: '2px solid white',
    borderRadius: 12,
    backgroundColor: Colors.purple1,
    height: 50,
    fontSize: '1.7rem',
    color: 'white',
    marginLeft: 15,

    '& .MuiSelect-iconStandard': {
        marginRight: 10,
        marginLeft: 10,
        fill: 'white',
    },
}));

const StyledMenuItemSecondary = styled(MenuItem)((props) => ({
    backgroundColor: props.theme.palette.primary.dark,
    color: 'white',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
        backgroundColor: props.theme.palette.primary.main,
    },
    '&.Mui-selected': {
        backgroundColor: props.theme.palette.primary.main,
        '&:hover': {
            backgroundColor: props.theme.palette.primary.main,
        },
    },
}));

const LanguageSelector = () => {
    const sendEvent = useGACustomEvent();
    const language = window.Weglot.getCurrentLang() || 'en';
    const [triggerRerender, setTriggerRerender] = useState(true);

    const handleUpdateLanguage = (evt) => {
        const incomingLanguage = evt.target.value === 'en' ? 'English' : 'Spanish';
        sendEvent(EVENTS.CHANGE_LANGUAGE_CLICKED, { language: incomingLanguage });

        setTriggerRerender((prev) => !prev);
        window.Weglot.switchTo(evt.target.value);
    };

    return (
        <StyledSelect
            data-test={triggerRerender}
            value={language}
            variant="standard"
            onChange={handleUpdateLanguage}
            IconComponent={KeyboardArrowDownIcon}
            color="primary"
            renderValue={(value) => (
                <>
                    <LanguageIcon />
                    <span style={{ paddingLeft: 5 }}>
                        {value === 'en' ? 'English' : 'Spanish'}
                    </span>
                </>
            )}
            SelectDisplayProps={{
                style: {
                    paddingRight: 35,
                },
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        marginTop: 2,
                        borderRadius: 5,
                        backgroundColor: Colors.purple2,
                    },
                    elevation: 1,
                },
            }}
        >
            <StyledMenuItemSecondary value="en" style={{ justifyContent: 'start', fontWeight: 600 }}><span style={{ width: 25 }}>{language === 'en' && <DoneOutlinedIcon />}</span>English</StyledMenuItemSecondary>
            <StyledMenuItemSecondary
                value="es"
                style={{ justifyContent: 'start', fontWeight: 600 }}
            >
                <span style={{ width: 25 }}>{language === 'es' && <DoneOutlinedIcon />}</span>Spanish
            </StyledMenuItemSecondary>
        </StyledSelect>
    );
};

export default LanguageSelector;
