import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Colors from '../../styles/colors';
import RegisterIcon from '../../images/icons/register-icon.png';

const NotRegisteredBlock = () => {
    const navigate = useNavigate();
    return (
        <Box style={{ backgroundColor: Colors.paperButtonBackground, borderTop: `1px solid ${Colors.grey3}` }} pb={4}>
            <Container>
                <Grid item container pt={12} pb={10}>
                    <Grid item xs={12} lg={8}>
                        <Typography variant="h2">Not Registered?</Typography>
                        <Typography variant="body1" style={{ fontSize: '2.1rem' }}>
                            If you want to connect with a helper or go to a workshop, you must register first.
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => navigate('/triage')}
                            sx={{ mt: 4 }}
                        >
                            Register Now
                        </Button>
                    </Grid>
                    <Grid item xs={4} sx={{ display: { xs: 'none', lg: 'flex' } }} justifyContent="end">
                        <img src={RegisterIcon} alt="" style={{ height: 200 }} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default NotRegisteredBlock;
