import { combineReducers } from 'redux';
import App from './app';
import Cms from './cms';

const Reducers = () => combineReducers({
    app: App,
    cms: Cms,
});

export default Reducers;
