import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

import PrimaryButton from '../../../components/buttons/primary-button';
import ErrorImg from './images/404-error@2x.png';
import ContentWrapper from '../../../components/content-wrapper';

const PrivacyPolicy = () => (
    <Container sx={{ pt: 6, pb: 6 }}>
        <ContentWrapper>
            <Grid container item>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h1" pb={1}>
                        404
                    </Typography>

                    <Typography variant="body2" pb={1}>
                        Page not found.
                    </Typography>

                    <Typography variant="body1" pb={8}>
                        The page you are looking for does not exist.
                    </Typography>

                    <PrimaryButton
                        component={Link}
                        to="/"
                    >
                        Back to Home
                    </PrimaryButton>
                    <div style={{ paddingTop: 60, paddingBottom: 30 }}>
                        <img src={ErrorImg} alt="" height="190" width="313" />
                    </div>
                </Grid>
            </Grid>
        </ContentWrapper>
    </Container>
);

export default PrivacyPolicy;
