import AppTypes from '../action-types/app';

const internals = {
    // TODO Is this unused, duplicative of / overwritten by same data in app selector?
    initial: () => ({
        mobileNavOpen: false,
        navigation: {
            learn: {
                label: 'Learn',
                path: 'learn',
                secondary: [
                    {
                        label: 'Overview',
                        path: 'overview',
                    },
                    {
                        label: 'Decision Making Tools',
                        path: 'decision',
                        id: 'decision',
                        tertiary: [
                            {
                                label: 'Power of Attorney',
                                path: 'power',
                                id: 'power',
                            },
                            {
                                label: 'Advance Health Care Directive',
                                path: 'durable',
                                id: 'durable',
                            },
                            {
                                label: 'Education Authorization',
                                path: 'education',
                                id: 'education',
                            },
                            {
                                label: 'Supported Decision Making Agreements',
                                path: 'supported',
                                id: 'supported',
                            },
                            {
                                label: 'Appointment of Representative for Medi-Cal and In Home Support Services (IHSS)',
                                path: 'authorized',
                                id: 'authorized',
                            },
                        ],
                    },
                    {
                        label: 'What is Conservatorship?',
                        path: 'conservatorship',
                        id: 'conservatorship',
                        tertiary: [
                            {
                                label: 'General Conservatorships',
                                path: 'general',
                                id: 'general',
                            },
                            {
                                label: 'Limited Conservatorships',
                                path: 'limited',
                                id: 'limited',
                            },
                            {
                                label: 'Mental Health (LPS) Conservatorships',
                                path: 'mental',
                                id: 'mental',
                            },
                        ],
                    },
                    {
                        label: 'All Conservatees Have Legal Rights',
                        path: 'rights',
                        id: 'rights',
                    },
                    {
                        label: 'Conservators Have Responsibilities',
                        path: 'responsibilities',
                        id: 'responsibilities',
                    },
                    {
                        label: 'Glossary',
                        path: 'glossary',
                        id: 'glossary',
                    },
                ],
            },
            support: {
                label: 'Get Support',
                path: 'support',
                secondary: [
                    {
                        label: 'Getting Started',
                        path: 'start',
                    },
                    {
                        label: 'Process Overview',
                        path: 'overview',
                    },
                    {
                        label: 'Review & Sign Forms',
                        path: '1',
                        id: 'review',
                        /*
                            id needed to correlate section with content. 'path' would have worked too,
                            but we migrated code out of that and didn't want to have to refactor it again
                        */
                    },
                    {
                        label: 'File Your Forms',
                        path: '2',
                        id: 'file',
                    },
                    {
                        label: 'Give Notice',
                        path: '3',
                        id: 'give',
                    },
                    {
                        label: 'Get Ready for Hearing',
                        path: '4',
                        id: 'prepare',
                    },
                    {
                        label: 'Go to Hearing',
                        path: '5',
                        id: 'day',
                    },
                    {
                        label: 'After the Hearing',
                        path: '6',
                        id: 'after',
                    },
                ],
            },
            about: {
                label: 'About Us',
                path: 'about',
            },
            language: {
                label: 'Language',
                path: 'language',
                secondary: [
                    {
                        label: 'English',
                        path: 'en',
                    },
                    {
                        label: 'Spanish',
                        path: 'es',
                    },
                ],
            },
        },
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case AppTypes.OPEN_CLOSE_MOBILE_NAVIGATION: {
        let navOpen;
        if (payload !== null) navOpen = payload;
        else navOpen = !state.mobileNavOpen;
        return {
            ...state,
            mobileNavOpen: navOpen,
        };
    }

    default:
        // do nothing
    }
    return state;
};

export default AppReducer;
