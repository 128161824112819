import React, { lazy } from 'react';
import {
    Routes,
    Route,
    Link as RouterLink,
    useLocation,
} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import StyledLink from '../../components/styled-breadcrumb';
import NotRegisteredBlock from '../../components/not-registered-block';

import SideNav from './components/side-nav';

const Error404 = lazy(() => import('../../routes/error/404'));
const SupportStep = lazy(() => import('../../routes/support/steps'));

const SideNavLayout = () => {
    const location = useLocation();
    return (
        <>
            <Container>
                <Grid item container pt={6} pb={8}>
                    <Grid item xs={12} md="auto">
                        <StyledLink to="../overview" component={RouterLink}>Back to Process Overview</StyledLink>
                        <SideNav />
                    </Grid>
                    <Grid item pt={{ xs: 4, lg: 10 }} style={{ flex: 1 }}>
                        <Routes>
                            {/* Support */}
                            <Route path="1" element={<SupportStep page="review" />} />
                            <Route path="2" element={<SupportStep page="file" />} />
                            <Route path="3" element={<SupportStep page="give" />} />
                            <Route path="4" element={<SupportStep page="prepare" />} />
                            <Route path="5" element={<SupportStep page="day" />} />
                            <Route path="6" element={<SupportStep page="after" />} />

                            <Route path="/*" element={<Error404 />} />
                        </Routes>
                    </Grid>
                </Grid>
            </Container>
            {location.pathname !== '/support/6' && (
                <NotRegisteredBlock />
            )}
        </>
    );
};

export default withRoot(SideNavLayout, themer);
