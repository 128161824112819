import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import ErrorContent from '../../routes/error/404';
import themer from '../../styles/material-theme';

const ErrorBoundary = () => (
    <ThemeProvider theme={themer}>
        <main>
            <ErrorContent />
        </main>
    </ThemeProvider>
);

export default ErrorBoundary;
