import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GA4React from 'ga-4-react';

/*

Note some configuration in GA is needed to support receiving and reporting on these events:

- Create a new event definition in GA4 for each event type (e.g. CTA_CLICKED, RESOURCES_TAB_CLICKED): https://support.google.com/analytics/answer/12229021?hl=en
- Create custom dimension for each event parameter (e.g. cta_name, category) on which we want to report: https://support.google.com/analytics/answer/14239696

Once those are set, GA will be able to import these events into reports / refer to them in custom report / explore views.

*/

export const EVENTS = {
    CTA_CLICKED: 'cta_clicked', // params: cta_name
    RESOURCES_TAB_CLICKED: 'resources_tab_clicked', // params: category
    CHANGE_LANGUAGE_CLICKED: 'update_language_clicked', // params: language
};

let ga4React = null;

export const useGA4 = () => {
    if (ga4React) {
        return ga4React;
    }

    if (process.env.REACT_APP_GA4) {
        ga4React = new GA4React(process.env.REACT_APP_GA4);
        ga4React.initialize().then(() => { ga4React.isInitialized = true; });
        return ga4React;
    }

    return null;
};

// https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
export const useGACustomEvent = () => {
    const ga = useGA4();
    return (event, parameters) => {
        if (Object.values(EVENTS).includes(event)) {
            // Drill down to the gtag instance since ga4React's event method is opinionated re:
            // parameters passed: https://github.com/unrealmanu/ga-4-react/blob/main/src/lib/ga4manager.tsx#L210-L221
            ga?.gtag('event', event, parameters);
        }
    };
};

const GoogleAnalytics = () => {
    const ga = useGA4();
    const location = useLocation();

    useEffect(() => {
        if (ga && ga.isInitialized) {
            ga.pageview(location.pathname, location.search, location.pathname);
        }
    }, [location]);

    return null;
};

export default GoogleAnalytics;
