import { createSelector } from 'reselect';

const getAppState = (state) => state.app;

export const getMobileNavigationOpenState = createSelector(
    getAppState,
    (appState) => appState.mobileNavOpen,
);

// context: we use to externalize all navigation in the cms, but we have since moved the "learn"
// section inside the app, but we are going to continue tom build the nav dynamically so we hardcode the learn section here
const learnNav = {
    label: 'Learn',
    path: 'learn',
    secondary: [
        {
            label: 'Overview',
            path: 'overview',
        },
        {
            label: 'Decision Making Tools',
            path: 'decision',
            id: 'decision',
            tertiary: [
                {
                    label: 'Power of Attorney',
                    path: 'power',
                    id: 'power',
                    description: 'A form that allows someone to help you with decisions about money and property.',
                },
                {
                    label: 'Advance Health Care Directive',
                    path: 'durable',
                    id: 'durable',
                    description: 'A form to have someone help with health care decisions.',
                },
                {
                    label: 'Education Authorization',
                    path: 'education',
                    id: 'education',
                    description: 'A legal tool that lets you choose someone to help you with your education.',
                },
                {
                    label: 'Supported Decision Making Agreements',
                    path: 'supported',
                    id: 'supported',
                    description: 'You will still make decisions on your own, but it lets you choose someone to give you help and information if you need it.',
                },
                {
                    label: 'Appointment of Representative for Medi-Cal and In Home Support Services (IHSS)',
                    path: 'authorized',
                    id: 'authorized',
                    description: 'This form can be used for Medi-Cal or In Home Supportive Services decisions.',
                },
            ],
        },
        {
            label: 'What is Conservatorship?',
            path: 'conservatorship',
            id: 'conservatorship',
            // HTML descriptions eventually parsed via dangerouslySetInnerHTML in the LearningBlock component
            tertiary: [
                {
                    label: 'General Conservatorships',
                    path: 'general',
                    id: 'general',
                    description: '<p>General conservatorships are for adults who cannot care for themselves on their own due to the impact of a serious illness, accident, and/or disability.</p><p>You can ask a probate court judge for a conservatorship to help with the person&apos;s care, money situation, or both.</p>',
                },
                {
                    label: 'Limited Conservatorships',
                    path: 'limited',
                    id: 'limited',
                    description: '<p>A limited conservatorship is when a judge appoints a conservator to make limited decisions (called powers) to protect an I/DD adult that cannot make decisions about their care, money situation, or both.</p><p>If a judge approves a limited conservatorship, the conservator must help the I/DD adult be as independent as possible, supporting the conservatee only in the areas where the conservatee needs help.</p>',
                },
                {
                    label: 'Mental Health (LPS) Conservatorships',
                    path: 'mental',
                    id: 'mental',
                    description: '<p>LPS conservatorships are also called mental health conservatorships. LPS conservatorships are for persons who are unsafe because of a grave (serious) mental health disability, including gravely disabled adults who cannot:</p><ul><li>take care of their basic personal needs, like food, clothes, or housing, or</li><li>keep themselves safe or get necessary medical care because of chronic alcoholism.</li></ul><p>Requests for an LPS conservatorship <b>must</b> come from the office of Public Guardian. A mental health court will decide if the LPS conservatorship is needed.</p>',
                },
            ],
        },
        {
            label: 'All Conservatees Have Legal Rights',
            path: 'rights',
            id: 'rights',
        },
        {
            label: 'Conservators Have Responsibilities',
            path: 'responsibilities',
            id: 'responsibilities',
        },
        {
            label: 'Glossary',
            path: 'glossary',
            id: 'glossary',
        },
    ],
};

export const getNavigation = createSelector(
    getAppState,
    (appState) => {
        const { navigation } = appState;
        navigation.learn = learnNav;
        return navigation;
    },
);
