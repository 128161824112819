import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';

import LearnLayout from '../learn';
import SupportLayout from '../support';

import Header from '../../components/header';
import Footer from '../../components/footer';

import Colors from '../../styles/colors';

const Error404 = lazy(() => import('../../routes/error/404'));
const Home = lazy(() => import('../../routes/home'));
const About = lazy(() => import('../../routes/about'));
const SupportOverview = lazy(() => import('../../routes/support/overview'));
const SupportGetStarted = lazy(() => import('../../routes/support/get-started'));
const LearnOverview = lazy(() => import('../../routes/learn/overview'));

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <Header />
        <main style={{ flex: '1 0 auto', backgroundColor: Colors.background }}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="support">
                    <Route index element={<SupportOverview />} />
                    <Route path="overview" element={<SupportOverview />} />
                    <Route path="start" element={<SupportGetStarted />} />
                    <Route path="*" element={<SupportLayout />} />
                </Route>
                <Route path="learn">
                    <Route index element={<LearnOverview />} />
                    <Route path="overview" exact element={<LearnOverview />} />
                    <Route path="*" element={<LearnLayout />} />
                </Route>
                <Route path="/error/404" element={<Error404 />} />
                <Route path="/*" element={<Navigate replace to="/error/404" />} />
            </Routes>
        </main>
        <Footer />
    </Suspense>
);

export default withRoot(PublicLayout, themer);
