import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@mui/material/styles/styled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Colors from '../../styles/colors';
import HeaderLogo from '../../images/logo-header.png';
import LanguageSelectorDesktop from '../language-selector-desktop';

const StyledHeader = styled('header')({
    backgroundColor: Colors.purple1,
    position: 'sticky',
    top: 0,
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)',
    zIndex: 1150,
});

const TriageHeader = () => (
    <StyledHeader>
        <Container style={{ height: 70, display: 'flex' }}>
            <Grid container alignItems="center" justifyContent="space-between" height="100%" pt={{ xs: 1, lg: 0 }} pb={{ xs: 1, lg: 0 }}>
                <Grid item xs>
                    <Link to="/">
                        <img src={HeaderLogo} alt="Bet Tzedek - justice for all" style={{ maxHeight: 54, maxWidth: '100%' }} />
                    </Link>
                </Grid>
                <Grid
                    item
                    xs="auto"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>
                        <Link to="/" style={{ paddingRight: 45, color: 'white', fontWeight: 600 }}>Back to Home</Link>
                    </Box>
                    <LanguageSelectorDesktop />
                </Grid>
            </Grid>
        </Container>
        <div style={{ background: 'linear-gradient(270deg, #B760DC 0%, #FEAE5A 100%)', height: 6 }} />
    </StyledHeader>
);

export default TriageHeader;
