import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Colors from '../../../styles/colors';

export const StyledSelect = styled(Select)(() => ({
    border: `1px solid ${Colors.grey4}`,
    borderRadius: 12,
    fontSize: '1.6rem',
    color: 'black',
    height: 50,
    width: '100%',
    maxWidth: 500,
    fontWeight: 400,
    '& svg': {
        fontSize: 22,
        fill: Colors.grey,
        color: Colors.grey,
    },
    '&:hover': {
        border: `1px solid ${Colors.grey4}`,
    },
}));

export const StyledMobilMenuItem = styled(MenuItem)(() => ({
    fontWeight: 400,
    color: Colors.grey2,
    '&:focus': {
        boxShadow: '0 0 5px 0 rgba(0,146,255,0.8)',
        backgroundColor: 'white',
    },
    '&.Mui-selected': {
        '& div': {
            backgroundColor: Colors.purple1,
        },
    },
}));
