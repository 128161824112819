import { createTheme } from '@mui/material/styles';
import Colors from './colors';

const baseTheme = createTheme({
    spacing: 5,
    padding: 5,
    typography: {
        fontFamily: '"Figtree", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        h1: {
            color: 'black',
            fontWeight: 700,
            lineHeight: 1.1,
        },
        h2: {
            color: 'black',
            fontWeight: 700,
        },
        h3: {
            color: 'black',
            fontWeight: 600,
        },
        h4: {
            color: 'black',
            fontWeight: 600,
        },
        h5: {
            color: 'black',
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        body1: {
            color: Colors.grey,
        },
        body2: {
            lineHeight: 1.3,
            color: Colors.grey,
        },
        button: {
            fontSize: '1.7rem',
            fontWeight: 600,
            textTransform: 'none',
        },
    },
    palette: {
        text: {
            primary: Colors.grey,
            secondary: Colors.grey,
        },
        primary: {
            main: Colors.purple1,
            dark: Colors.purple2,
            light: Colors.brightPink,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.midnight,
            light: Colors.midnightLight,
            contrastText: Colors.white,
        },
        tertiary: {
            main: Colors.mist,
            light: Colors.mistLight,
            contrastText: Colors.black,
        },
        quaternary: {
            main: Colors.tAndPink,
        },
        background: {
            default: Colors.whiteDark,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        h1: {
            ...baseTheme.typography.h1,
            fontSize: '4.2rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '3.6rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '3.0rem',
            },
        },
        h2: {
            ...baseTheme.typography.h2,
            fontSize: '2.8rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '2.6rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
            },
        },
        h3: {
            ...baseTheme.typography.h3,
            fontSize: '2.4rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '2.2rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '2.1rem',
            },
        },
        h4: {
            ...baseTheme.typography.h4,
            fontSize: '1.8rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '1.7rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.7rem',
            },
        },
        h5: {
            ...baseTheme.typography.h5,
            fontSize: '1.6rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '1.6rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        body1: {
            ...baseTheme.typography.body1,
            fontSize: '1.6rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '1.6rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
            '& a': {
                color: baseTheme.palette.primary.main,
                fontWeight: 600,
            },
        },
        body2: {
            ...baseTheme.typography.body2,
            color: baseTheme.palette.text.secondary,
            '& a': {
                color: baseTheme.palette.primary.main,
                fontWeight: 600,
            },
            fontSize: '2.1rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '2.0rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.9rem',
            },
        },
        a: {
            color: baseTheme.palette.primary.main,
            textDecoration: 'underline',
        },
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: baseTheme.palette.primary.main,
                    height: 4,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    paddingLeft: 30,
                    paddingRight: 30,
                    [baseTheme.breakpoints.down('sm')]: {
                        paddingLeft: 5,
                        paddingRight: 5,
                    },
                    fontWeight: 400,
                    color: 'black',
                    '&.Mui-selected': {
                        fontWeight: 600,
                        backgroundColor: 'white',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingLeft: 15,
                    '&#language': {
                        // paddingBottom: 0,
                    },
                    '&[role=button]': {
                        // paddingRight: 18,
                    },
                },
                root: {
                    ...baseTheme.typography.button,
                    fontWeight: 600,
                    color: baseTheme.palette.primary.main,
                    '& .MuiSvgIcon-root': {
                        // fill: baseTheme.palette.primary.main,
                    },
                    '&:hover::before': {
                        borderBottom: '0 !important',
                    },
                    '&::after, ::before': {
                        border: 0,
                    },
                },

            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: baseTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    textAlign: 'left',
                    padding: '20px 15px',
                    color: 'black',
                    fontSize: '1.6rem',
                    lineHeight: 1.2,
                    fontWeight: 600,
                    borderRadius: 12,
                    '&:hover': {
                        backgroundColor: `rgba(${Colors.purple3RGB}, 0.5)`,
                    },
                    '&:focus': {
                        border: '2px solid #0092FF',
                        boxShadow: '0 0 5px 0 rgba(0,146,255,0.8)',
                    },
                    '& svg': {
                        display: 'inline',
                        marginRight: 15,
                    },
                    '& ul': {
                        marginTop: 5,
                    },
                    '& li, .list-title': {
                        fontWeight: 400,
                        color: Colors.grey,
                        fontSize: '1.6rem',
                    },
                    '&.Mui-selected': {
                        color: 'black',
                        backgroundColor: `rgba(${Colors.purple3RGB}, 0.5)`,
                        border: `1px solid ${baseTheme.palette.primary.main}`,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 'inherit',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    position: 'relative',
                    color: 'black',
                    paddingBottom: 4,
                    top: 0,
                    left: 0,
                    '&.Mui-focused': {
                        color: 'black',
                    },
                },
                asterisk: {
                    color: baseTheme.palette.error.main,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                    '&.Mui-focused': {
                        color: 'black',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    marginLeft: 0,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.dark,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: 'black',
                    backgroundColor: Colors.white,
                    fontSize: '1.5rem',
                    marginTop: 5,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation2: { // regular menus
                    borderRadius: 5,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                },
                elevation24: { // dialogs
                    borderRadius: 5,
                    boxShadow: `0 0 10px 0 ${baseTheme.palette.tertiary}`,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.secondary,
                    lineHeight: 1.1875,
                    fontSize: '1.6rem',

                    '&:hover': {
                        // color: baseTheme.palette.primary.main,
                    },

                    '&[aria-selected=true]': {
                        // color: baseTheme.palette.primary.contrastText,
                        // backgroundColor: baseTheme.palette.primary.hover,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 10,
                    '&.active': { // primary nav
                        paddingBottom: 14,
                        borderBottom: `4px solid ${baseTheme.palette.primary.dark}`,
                        backgroundColor: baseTheme.palette.primary.dark,
                        '&[aria-expanded=true]': {
                            '& svg': {
                                color: Colors.yellow2,
                            },
                        },
                    },
                    '&.sideNavLearn': {
                        color: 'black',
                        paddingRight: 25,
                        justifyContent: 'left',
                        fontWeight: 400,
                        fontSize: '1.6rem',
                        padding: 10,
                        '&.sideNavActiveLearn': {
                            paddingRight: 25,
                            fontWeight: 700,
                            backgroundColor: `rgba(${Colors.purple3RGB}, 0.8)`,
                            borderRadius: 20,
                        },
                    },
                    '&.sideNavTertiaryLearn': {
                        color: Colors.grey,
                        width: '100%',
                        justifyContent: 'left',
                        fontWeight: 400,
                        fontSize: '1.5rem',
                        padding: 10,
                        paddingLeft: 15,
                        lineHeight: 1.2,
                        '&.sideNavActiveTertiaryLearn': {
                            fontWeight: 700,
                            color: 'black',
                        },
                    },
                    '&.sideNavSupport': {
                        color: 'black',
                        width: '100%',
                        justifyContent: 'left',
                        fontWeight: 400,
                        fontSize: '1.6rem',
                        marginBottom: 5,
                        padding: 10,
                        '& .number': {
                            height: 30,
                            width: 30,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '1.3rem',
                            backgroundColor: Colors.grey2,
                            borderRadius: 30,
                            color: 'white',
                            marginRight: 15,
                            paddingTop: 1,
                        },
                        '&.sideNavActiveSupport': {
                            fontWeight: 700,
                            borderRadius: 20,
                            backgroundColor: `rgba(${Colors.purple3RGB}, 0.8)`,
                            '& .number': {
                                backgroundColor: baseTheme.palette.primary.main,
                            },
                        },
                    },
                },
                containedPrimary: {
                    backgroundColor: Colors.purple1,
                    borderRadius: 12,
                    paddingRight: 25,
                    paddingLeft: 25,
                    '&:hover, :active': {
                        backgroundColor: baseTheme.palette.primary.dark,
                    },
                    '&:focus': {
                        backgroundColor: baseTheme.palette.primary.dark,
                        border: `2px solid ${Colors.blue}`,
                        boxShadow: `0 0 5px 0 rgba(${Colors.blueRGB},0.8)`,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: Colors.purple1,
                        color: 'white',
                        opacity: 0.3,
                    },
                },
                containedSecondary: {
                    backgroundColor: `rgba(${Colors.purple3RGB},0.8)`,
                    color: baseTheme.palette.primary.dark,
                    borderRadius: 12,
                    paddingRight: 25,
                    paddingLeft: 25,
                    '&:hover, :active': {
                        backgroundColor: Colors.purple3,
                    },
                    '&:focus': {
                        backgroundColor: Colors.purple3,
                        border: `2px solid ${Colors.blue}`,
                        boxShadow: `0 0 5px 0 rgba(${Colors.blueRGB},0.8)`,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: `rgba(${Colors.purple3RGB},0.8)`,
                        color: baseTheme.palette.primary.dark,
                        opacity: 0.5,
                    },
                },
                containedTertiary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.tertiary.light,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                },
            },
        },
    },
});

export default theme;
