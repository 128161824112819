export default {
    background: '#FFFFFF',
    purple1: '#6260dc', // primary
    purple1RGB: '85, 83, 210',
    purple2: '#3B3992', // dark
    purple3: '#DDDDF6', // lavendar
    purple3RGB: '221, 221, 246',
    yellow: '#FEAE5A', // marigold
    yellow2: '#FEBE7B', // honey
    yellow2RGB: '254, 190, 123',
    grey: '#444746',
    greyRGB: '68, 71, 70',
    grey2: '#333333',
    grey3: '#E0E3E7',
    grey4: '#D7D9DB',
    paperButtonBackground: '#F7F9F9',
    blue: '#0092FF',
    blueRGB: '0,146,255',
    error: '#cc0053',
    errorRGB: '204,0,83',
    mainGradient: 'linear-gradient(180deg, #6260DC 0%, #FDA651 100%)',

    // old
    white: '#ffffff',
    whiteDark: '#F4F4F5',
    black: '#2F3135',
    blackRGB: '47,49,53',
    mist: '#CFDAE6',
    mistLight: '#D8E3F0',

    greyDark: '#6C6E71',
    greyLight: '#E9EAEB',
    regalBlue: '#1D2E4B',
    tAndPink: '#FF0080',
    brightPink: '#FC409E',
    midnight: '#0F2032',
    midnightLight: '#162F4A',
    success: '#13AE47',
    linkBlue: '#0080FF',
};
