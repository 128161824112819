import format from 'date-fns/format';
import WebClient from './web-client';

export const getLocale = (wpmlLocale) => {
    if (wpmlLocale === 'en_US') {
        return 'en';
    }
    if (wpmlLocale === 'es_ES') {
        return 'es';
    }
    return '';
};

/**
 * Transforms a model from wordpress, which we assume to use custom post types and
 * advanced custom fields. Input models will have the following structure: {
 *      id: [id],
 *      date: [created date],
 *      type: [custom Post type],
 *      menu_order: [displayed order]
 *      acf: {
 *          customField1: customValue1,
 *          ...
 *      },
 * }
 * @param {*} acfModel
 * @returns an object with acf fields at the first level, and some key meta information attached
 *  {
 *      id,
 *      postType,
 *      globalOrder,
 *      ...customFields
 *  }
 */
export const transformACFModel = (acfModel) => {
    const locale = getLocale(acfModel.wpml_current_locale);
    const translations = {};
    acfModel.wpml_translations?.forEach((translation) => {
        const translatedLocale = getLocale(translation.locale);
        translations[translation.id] = translatedLocale;
    });
    return {
        ...acfModel.acf,
        id: acfModel.id,
        postType: acfModel.type,
        globalOrder: acfModel.menu_order,
        createdAt: acfModel.date,
        locale,
        translations,
    };
};

/**
 * Wordpress unfortunately returns empty relationships as null,
 * but will return an array of ids if relationships are found.
 * This helper function simply sets a relationship to [] if no
 * relations exist.
 * @param {*} model
 * @param {*} relationshipFields
 * @returns
 */
export const normalizeEmptyRelationships = (model, relationshipFields) => {
    const modelToReturn = { ...model };
    relationshipFields.forEach((field) => {
        if (!modelToReturn[field]) {
            modelToReturn[field] = [];
        }
    });
    return modelToReturn;
};

/**
 * Converts wordpress date strings into Date objects. Assumes that the Wordpress API is
 * configured to return date strings as yyyymmdd.
 * @param {*} model
 * @param {*} dateFields
 */
export const transformDateFields = (model, dateFields) => {
    const converted = { ...model };
    dateFields.forEach((fieldName) => {
        const dateString = model[fieldName];
        if (dateString) {
            const date = new Date();
            const year = Number(dateString.substring(0, 4));
            const month = Number(dateString.substring(4, 6)) - 1;
            const day = Number(dateString.substring(6, 8));
            date.setFullYear(year, month, day);
            converted[fieldName] = date;
        }
    });
    return converted;
};

/**
 * Converts wordpress date strings into Date objects. Assumes that the Wordpress API is
 * configured to return date strings as yyyymmdd.
 * @param {*} model
 * @param {*} dateFields
 */
export const formatDate = (rawDate) => {
    const date = new Date();
    const year = Number(rawDate.substring(6, 10));
    const month = Number(rawDate.substring(0, 2)) - 1;
    const day = Number(rawDate.substring(3, 5));
    date.setFullYear(year, month, day);
    return format(date, 'MMMM d, yyyy');
};

/**
 * Converts strings contained in the booleanFields to boolean values
 * @param {*} model
 * @param {*} booleanFields
 * @returns
 */
export const transformBooleanFields = (model, booleanFields) => {
    const converted = { ...model };
    booleanFields.forEach((fieldName) => {
        converted[fieldName] = model[fieldName]?.toLowerCase() === 'true';
    });
    return converted;
};

/**
 * Formats a wordpress time string from hh:mm:ss to h:mm am/pm
 */
export const formatTimeString = (timeString) => {
    const timeParts = timeString.split(':');
    let hours = Number(timeParts[0]);
    const minutes = timeParts[1];
    let ampm = 'am';
    if (hours > 12) {
        hours -= 12;
        ampm = 'pm';
    }
    return `${hours}:${minutes} ${ampm}`;
};

/**
 * This function flattens a collection of ACF models from wordpress,
 * mapping them by id
 * @param {*} acfCollection
 * @param {*} options
 * @returns a map of flattened ACF models keyed by id
 *  {
 *      [id]: transformedModel,
 *      ...
 *  }
 */
export const mapACFCollectionById = (acfCollection, options = {}) => {
    const { relationshipFields = [], dateFields = [], booleanFields = [] } = options;
    const finalMap = {};
    acfCollection.forEach((acfModel) => {
        finalMap[acfModel.id] = transformBooleanFields(
            transformDateFields(
                normalizeEmptyRelationships(
                    transformACFModel(acfModel),
                    relationshipFields,
                ),
                dateFields,
            ),
            booleanFields,
        );
    });
    return finalMap;
};

/**
 * Performs exactly like mapACFCollectionByAttribute, except that it keys the
 * collection off of a custom attribute instead
 * @param {*} acfCollection
 * @param {*} attribute
 * @param {*} options
 * @returns {
 *      [attribute]: transformedModel,
 *      ...
 * }
 */
export const mapACFCollectionByAttribute = (acfCollection, attribute, options = {}) => {
    const { relationshipFields = [], dateFields = [] } = options;
    const finalMap = {};
    acfCollection.forEach((acfModel) => {
        finalMap[acfModel.acf[attribute]] = transformDateFields(
            normalizeEmptyRelationships(
                transformACFModel(acfModel),
                relationshipFields,
            ),
            dateFields,
        );
    });
    return finalMap;
};

/**
 * Wordpress automatically limits page size to 10, and allows a maximum page size of 100
 * If we don't want to paginate our resources, then this function will keep fetching until
 * all resources have been fetched.
 * @param {*} resourceSlug
 * @param {*} config for axios
 * @returns the combined wordpress response data from each fetch as a list
 */
export const fetchAll = async (resourceSlug, config = {}) => {
    const { params } = config;
    const response = await WebClient.get(`${process.env.REACT_APP_API_PREFIX}/${resourceSlug}`, {
        ...config,
        params: {
            ...params,
            per_page: 100,
        },
    });
    const totalPages = response.headers['x-wp-totalpages'];
    const allFetches = [];
    if (totalPages && totalPages > 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 2; i <= totalPages; i++) {
            allFetches.push(WebClient.get(resourceSlug, {
                ...config,
                params: {
                    ...params,
                    per_page: 100,
                    page: i,
                },
            }));
        }
        const subsequentResults = [];
        (await Promise.all(allFetches)).forEach(({ data }) => subsequentResults.push(...data));
        return response.data.concat(subsequentResults);
    }
    return response.data;
};

export const getTranslatedModel = (id, collection, locale) => {
    let model = collection[id];
    // if the model was found, it must be either this locale or the default language fallback
    if (model) {
        return model;
    }
    // check to see if this model has an associated translation
    model = Object.values(collection).find((item) => item.translations[id] && item.locale === locale);
    return model || {};
};

/* a special mapping function that takes ACF models representing page-copy and distills them to a model of the following structure:
* {
*    PageName: {
*       messageKey: messageValue,
*       ...
*   }
* }
*/
export const mapPageCopy = (messages, primaryField) => {
    const mappedCopy = {};
    messages.forEach((page) => {
        mappedCopy[page.acf[primaryField]] = {};
        page.acf.messages.forEach((message) => {
            mappedCopy[page.acf[primaryField]][message.key] = message.content;
        });
    });
    return mappedCopy;
};
