import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import Container from '@mui/material/Container';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import Colors from '../../styles/colors';

import Header from '../../components/header-triage';

const SupportTriageDisclaimer = lazy(() => import('../../routes/support/triage/disclaimer'));
const SupportTriageStep1 = lazy(() => import('../../routes/support/triage/step1'));
const SupportTriageStep1Exit = lazy(() => import('../../routes/support/triage/step1/exit'));
const SupportTriageStep2 = lazy(() => import('../../routes/support/triage/step2'));
const SupportTriageStep2Exit = lazy(() => import('../../routes/support/triage/step2/exit'));
const SupportTriageStep3 = lazy(() => import('../../routes/support/triage/step3'));
const SupportTriageStep3Exit = lazy(() => import('../../routes/support/triage/step3/exit'));
const SupportTriageHealthResults = lazy(() => import('../../routes/support/triage/results/health'));
const SupportTriageIntellectResults = lazy(() => import('../../routes/support/triage/results/intellect'));
const SupportTriageDIYOverview = lazy(() => import('../../routes/support/triage/diy'));
const SupportTriageRegister = lazy(() => import('../../routes/support/triage/register'));

const TriageLayout = () => (
    <Suspense fallback={<div />}>
        <Header />
        <main style={{ flex: '1 0 auto', backgroundColor: Colors.background }}>
            <Container>
                <Routes>
                    <Route path="/" element={<SupportTriageDisclaimer />} />
                    <Route path="disclaimer" element={<SupportTriageDisclaimer />} />
                    <Route path="1" element={<SupportTriageStep1 />} />
                    <Route path="1out" element={<SupportTriageStep1Exit />} />
                    <Route path="2" element={<SupportTriageStep2 />} />
                    <Route path="2out" element={<SupportTriageStep2Exit />} />
                    <Route path="3" element={<SupportTriageStep3 />} />
                    <Route path="3out" element={<SupportTriageStep3Exit />} />
                    <Route path="results">
                        <Route path="health" element={<SupportTriageHealthResults />} />
                        <Route path="intellect" element={<SupportTriageIntellectResults />} />
                        <Route path="disorder" element={<SupportTriageStep3Exit />} />
                    </Route>
                    <Route path="register" element={<SupportTriageRegister />} />
                    <Route path="diy">
                        <Route index element={<SupportTriageDIYOverview />} />
                    </Route>
                    <Route path="/*" element={<Navigate replace to="/error/404" />} />
                </Routes>
            </Container>
        </main>
    </Suspense>
);

export default withRoot(TriageLayout, themer);
