import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import FooterLogo from '../../../images/bet-tzedek-logo-footer.png';
import { OpenMobileNavigation } from '../../../actions/app';

const FooterText = styled(Typography)(() => ({
    color: 'white',
    '& a': {
        fontWeight: 400,
        color: 'white',
        textDecoration: 'none',
    },
}));

const StyledHR = styled('hr')(() => ({
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
}));

const MobileFooter = () => {
    const currentYear = new Date().getFullYear();
    const dispatch = useDispatch();
    const openNav = () => {
        dispatch(OpenMobileNavigation(true));
    };

    return (
        <Grid container spacing={6} mt={4} pb={4} pl={3} display="flex" flexDirection="column">
            <Grid item container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={FooterLogo} alt="" style={{ width: 150 }} />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FooterText variant="h4" pt={4}>Self-Help Conservatorship Clinic (SHCC)</FooterText>
                    <Button
                        onClick={() => openNav()}
                        variant="outlined"
                        style={{
                            border: '1px solid rgba(255,255,2550,0.5)',
                            borderRadius: 12,
                            color: 'white',
                            marginTop: 15,
                            width: 'fit-content',
                            paddingRight: 30,
                        }}
                    >
                        <MenuIcon style={{ color: 'white', marginRight: 10 }} />Menu
                    </Button>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={12} pb={3}>
                    <StyledHR />
                </Grid>
                <Grid item>
                    <FooterText variant="h4" sx={{ pb: 2 }}>Contact</FooterText>
                    <FooterText variant="body1" pb={2}><MailOutlineIcon sx={{ mr: 1.6, fontSize: '20px' }} /><a href="mailto:cclinic@bettzedek.org" target="_blank" rel="noopener noreferrer">cclinic@bettzedek.org</a></FooterText>
                    <FooterText variant="body1"><PhoneOutlinedIcon sx={{ mr: 1.6, fontSize: '20px' }} /><a href="tel:3235495863">(323) 549-5863</a></FooterText>
                </Grid>
                <Grid item xs={12} pt={3} pb={3}>
                    <StyledHR />
                </Grid>
                <Grid item xs={12}>
                    <FooterText variant="body1" component="span" style={{ fontSize: '1.4rem' }}>&copy; {currentYear} BT SHCC</FooterText>
                </Grid>
                <Grid item xs={12} pt={2}>
                    <FooterText variant="body1" component="span" style={{ display: 'inline' }}><Link href="https://bettzedek.org/privacy-policy/" target="_blank" rel="noopener" style={{ color: 'white', fontSize: '1.4rem', textDecoration: 'underline' }}>Privacy Policy &amp; Terms of Service</Link></FooterText>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MobileFooter;
