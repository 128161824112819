import CmsTypes from '../action-types/cms';
import { fetchAll } from '../utils/cms';

export const receiveModels = (postType, models) => ({
    type: CmsTypes.RECEIVE_MODELS,
    payload: {
        postType,
        models,
    },
});

export const receiveCmsError = (error, postType) => ({
    type: CmsTypes.CMS_ERROR,
    payload: { error, postType },
});

/**
 * Fetches a collection of models for a given post type
 * @param {*} postType
 * @returns
 */
export const fetchModels = (postType) => async (dispatch) => {
    dispatch({
        type: CmsTypes.LOADING_MODELS,
        payload: postType,
    });
    try {
        const models = await fetchAll(postType);
        dispatch(receiveModels(
            postType,
            models, // mapACFCollectionById(models, options),
        ));
    } catch (e) {
        // handle error
        receiveCmsError(e, postType);
    }
};
