import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from '@mui/material/styles/styled';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import HeaderLogo from '../../images/logo-header.png';
import Navigation from './components/nav';
import { OpenMobileNavigation } from '../../actions/app';
import { getMobileNavigationOpenState } from '../../selectors/app';

import Colors from '../../styles/colors';

const StyledHeader = styled('header')({
    backgroundColor: Colors.purple1,
    position: 'sticky',
    top: 0,
    zIndex: 1150,
});

const Header = () => {
    const dispatch = useDispatch();
    const mobileNavigationOpenState = useSelector(getMobileNavigationOpenState);

    const handleOpenClose = () => {
        dispatch(OpenMobileNavigation());
    };

    return (
        <StyledHeader>
            <Container style={{ height: '100%' }}>
                <Grid container alignItems="center" justifyContent="space-between" height="100%" style={{ flexGrow: 1 }} pt={{ xs: 1, lg: 0 }} pb={{ xs: 1, lg: 0 }}>
                    <Grid item>
                        <Link to="/">
                            <img src={HeaderLogo} alt="Bet Tzedek - justice for all" style={{ height: 54 }} />
                        </Link>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: { xs: 'none', lg: 'flex' },
                            flexGrow: 1,
                            height: '100%',
                        }}
                    >
                        <Navigation />
                    </Grid>
                    <Grid item sx={{ display: { xs: 'flex', lg: 'none' }, flexGrow: 1, justifyContent: 'end' }}>
                        <IconButton
                            aria-label="Open and close menu"
                            onClick={handleOpenClose}
                            edge="end"
                            size="large"
                            style={{ marginRight: -10 }}
                            // disableFocusRipple
                        >
                            {mobileNavigationOpenState ? <CloseIcon fontSize="large" style={{ fill: 'white' }} /> : <MenuIcon fontSize="large" style={{ fill: 'white' }} />}
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        </StyledHeader>
    );
};

export default Header;
