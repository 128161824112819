import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import { StyledSelect, StyledMobilMenuItem } from '../../../../components/inputs/styled-secondary-select';
import { getNavigation } from '../../../../selectors/app';
import Colors from '../../../../styles/colors';

const ButtonLinkSecondary = forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (
        <NavLink
            ref={ref}
            className={({ isActive }) => (
                isActive ? `${className} sideNavSupport sideNavActiveSupport` : `${className} sideNavSupport`
            )}
            {...otherProps}
        />
    );
});

ButtonLinkSecondary.propTypes = {
    className: PropTypes.string.isRequired,
};

const StyledDot = styled('div')(() => ({
    color: 'white',
    backgroundColor: Colors.grey2,
    height: 30,
    width: 30,
    borderRadius: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.3rem',
    fontWeight: 700,
    marginRight: 15,
}));

const SupportSideNav = () => {
    const type = 'support';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const navigation = useSelector(getNavigation);
    const location = useLocation();
    const [selectedValue, setSelectedValue] = useState('');
    const [selectiveNav, setSelectiveNav] = useState([]);
    useEffect(() => {
        const splitURL = location?.pathname.toString().split('/');
        splitURL.splice(0, 2); // array contains unneeded item from start of array
        const mergedArray = splitURL.join('/');
        setSelectedValue(mergedArray);
    }, [location]);

    const navigate = useNavigate();
    const handleMobileNavChange = (evt) => {
        navigate(evt.target.value);
    };

    useEffect(() => {
        setSelectiveNav(navigation[type].secondary.slice(2));
    }, [navigation]);

    const desktopNav = () => {
        const computedNav = selectiveNav.map((item, i) => (
            <Button
                key={`${item.path}-sidenav-desktop`}
                to={item.path}
                component={ButtonLinkSecondary}
            >
                <div className="number">
                    {i + 1}
                </div>
                {item.label}
            </Button>
        ));
        return computedNav;
    };
    const mobileNav = () => {
        const navItems = selectiveNav.map((item, i) => (
            <StyledMobilMenuItem
                key={item.path}
                value={item.path}
            >
                <StyledDot>{i + 1}</StyledDot><span>{item.label}</span>
            </StyledMobilMenuItem>
        ));

        return (
            <div style={{ textAlign: 'left', width: '100%' }}>
                <FormLabel
                    style={{
                        textTransform: 'none',
                        fontSize: '1.6rem',
                        display: 'block',
                        fontWeight: '700',
                        color: 'black',
                    }}
                >
                    Your step:
                </FormLabel>
                <StyledSelect
                    value={selectedValue}
                    onChange={handleMobileNavChange}
                    renderValue={(value) => `${value}. ${selectiveNav.find((item) => item.path === value).label}`}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                borderRadius: 12,
                            },
                        },
                    }}
                >
                    {navItems}
                </StyledSelect>
            </div>
        );
    };
    return (
        <Grid container alignItems="center" height="100%" style={{ flexGrow: 1 }} component="section">
            <Grid
                item
                sx={{
                    width: !isMobile ? 300 : 'fit-content',
                    display: 'flex',
                    flexGrow: 1,
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: { xs: 'center', lg: 'start' },
                    pr: { xs: 0, lg: 8 },
                }}
            >
                {!isMobile && desktopNav()}
                {isMobile && mobileNav()}
            </Grid>
        </Grid>
    );
};

export default SupportSideNav;
