import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import LanguageSelectorDesktop from '../../../language-selector-desktop';

import {
    NavDesktopContainer,
    NavMobilContainer,
} from '../nav-elements';

import { OpenMobileNavigation } from '../../../../actions/app';
import { getNavigation } from '../../../../selectors/app';
import Colors from '../../../../styles/colors';

const StyledTopLevelButton = styled(Button)((props) => ({
    fontSize: '1.7rem',
    fontWeight: 600,
    height: 73,
    borderRadius: 0,
    backgroundColor: props.theme.palette.primary.main,
    color: 'white',
    paddingLeft: 25,
    paddingTop: 20,
    paddingBottom: 20,
    boxSizing: 'border-box',
    '&:focus-visible': {
        backgroundColor: Colors.purple2,
        border: '2px solid #0092FF',
        boxShadow: '0 0 5px 0 rgba(0,146,255,0.8)',
    },
    '& svg': {
        marginLeft: 5,
    },
    '&:hover': {
        backgroundColor: props.theme.palette.primary.dark,
    },
}));

const StyledMobileButton = styled(Button)((props) => ({
    fontSize: '1.7rem',
    fontWeight: 600,
    width: '100%',
    borderRadius: 0,
    backgroundColor: props.theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingLeft: 30,
    paddingBottom: 10,
    borderBottom: `1px solid rgba(${Colors.purple3RGB}, 0.5)`,
    '&:hover': {
        backgroundColor: props.theme.palette.primary.dark,
    },
    '&.mobile-primary-active': {
        backgroundColor: props.theme.palette.primary.dark,
    },
}));

const StyledMobileSecondaryWrapper = styled(Box)((props) => ({
    backgroundColor: props.theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: 20,
}));

const StyledMenuItemSecondary = styled(MenuItem)((props) => ({
    backgroundColor: props.theme.palette.primary.dark,
    color: 'white',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 300,
    '&:hover': {
        backgroundColor: props.theme.palette.primary.main,
    },
    '&.Mui-selected': {
        backgroundColor: props.theme.palette.primary.main,
        '&:hover': {
            backgroundColor: props.theme.palette.primary.main,
        },
    },
}));

const StyledMenuItemTeritary = styled(MenuItem)((props) => ({
    backgroundColor: props.theme.palette.primary.main,
    paddingTop: 10,
    paddingBottom: 10,
    color: 'white',
    whiteSpace: 'break-spaces',
    width: 300,
    '&:hover': {
        backgroundColor: props.theme.palette.primary.dark,
    },
}));

const TopLevelNavButton = (props) => {
    const { to, children } = props;
    const match = useMatch(`${to}/*`);
    return (
        <StyledTopLevelButton className={match ? 'active' : ''} {...props}>{children}</StyledTopLevelButton>
    );
};

TopLevelNavButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

const Navigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const navigation = useSelector(getNavigation);

    const [primaryAnchorEl, setPrimaryAnchorEl] = useState(null);
    const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
    const primaryOpen = Boolean(primaryAnchorEl);
    const secodaryOpen = Boolean(secondaryAnchorEl);
    const [selectedPrimaryMenu, setSelectedPrimaryMenu] = useState(null);
    const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState(null);
    const matchHomePage = useMatch('/');
    const handlePrimaryBlur = () => {
        setSelectedPrimaryMenu(null);
        setPrimaryAnchorEl(null);
    };
    const handlePrimaryClick = useCallback((event, item) => {
        // if user is clicking an already open menu, deselect it
        if (selectedPrimaryMenu?.path === navigation[item]?.path) {
            handlePrimaryBlur();
        } else {
            setSelectedPrimaryMenu(navigation[item]);
            setPrimaryAnchorEl(event.currentTarget);
        }
    }, [selectedPrimaryMenu, navigation]);

    const handleSecondaryBlur = () => {
        setSelectedSecondaryMenu(null);
        setSecondaryAnchorEl(null);
    };
    const handleSecondaryFocus = (event, item) => {
        setSelectedSecondaryMenu(item);
        setSecondaryAnchorEl(event.currentTarget);
        if (!item.tertiary) {
            handleSecondaryBlur();
        }
    };

    const handleClose = () => {
        handlePrimaryBlur();
        handleSecondaryBlur();
        dispatch(OpenMobileNavigation(false));
    };

    const mobileSubNav = () => {
        const computedNav = selectedPrimaryMenu?.secondary?.map((item, i) => {
            let { label } = item;
            if (selectedPrimaryMenu.path === 'support' && i > 1) {
                label = `${item.path}. ${item.label}`;
            }
            return (
                <React.Fragment key={`${item.path}-mobile`}>
                    <Button
                        onClick={() => { navigate(`${selectedPrimaryMenu.path}/${item.path}`); handleClose(); }}
                        style={{ color: 'white' }}
                    >
                        {label}
                    </Button>
                </React.Fragment>
            );
        });

        return computedNav;
    };

    return (
        <nav
            role="navigation"
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
            }}
        >
            <NavDesktopContainer>
                {!isMobile && primaryOpen && (
                    <>
                        <Menu
                            id="primary-secondary"
                            anchorEl={primaryAnchorEl}
                            open={primaryOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': `${selectedPrimaryMenu?.label}-menu`,
                            }}
                            PaperProps={{
                                sx: {
                                    borderRadius: matchHomePage ? '10px' : '0 0 10px 10px',
                                    backgroundColor: Colors.purple2,
                                },
                                elevation: 0,
                            }}
                        >
                            {selectedPrimaryMenu?.secondary?.map((item) => {
                                let navLabel = item.label;
                                if (selectedPrimaryMenu.path === 'support' && !Number.isNaN(parseInt(item.path, 10))) navLabel = `${item.path}. ${item.label}`;
                                if (item.tertiary) {
                                    return (
                                        <StyledMenuItemSecondary
                                            // disableFocusRipple
                                            key={item.label}
                                            onClick={() => { handleClose(); navigate(`${selectedPrimaryMenu.path}/${item.path}`); }}
                                            onMouseOver={(evt) => handleSecondaryFocus(evt, item)}
                                        >
                                            {navLabel} <KeyboardArrowRightIcon />
                                        </StyledMenuItemSecondary>
                                    );
                                }
                                return (
                                    <StyledMenuItemSecondary
                                        // disableFocusRipple
                                        key={item.label}
                                        onClick={() => { handleClose(); navigate(`${selectedPrimaryMenu.path}/${item.path}`); }}
                                        onMouseOver={(evt) => handleSecondaryFocus(evt, item)}
                                    >
                                        {navLabel}
                                    </StyledMenuItemSecondary>
                                );
                            })}
                        </Menu>
                        <Menu
                            id="tertiary-menu"
                            anchorEl={secondaryAnchorEl}
                            open={secodaryOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': `${selectedSecondaryMenu?.label}-menu`,
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            hideBackdrop
                            style={{ width: 'fit-content', height: 'fit-content' }}
                            PaperProps={{
                                sx: {
                                    maxWidth: 300,
                                    maxHeight: 'fit-content',
                                    backgroundColor: Colors.purple1,
                                    borderRadius: '10px',
                                    // marginLeft: '8px',
                                    boxShadow: '0 4px 11px -7px rgba(0,0,0,0.5)',
                                },
                                elevation: 0,
                            }}
                        >
                            {selectedPrimaryMenu && selectedSecondaryMenu?.tertiary?.map((item) => (
                                <StyledMenuItemTeritary
                                    key={item.label}
                                    onClick={() => { handleClose(); navigate(`${selectedPrimaryMenu.path}/${selectedSecondaryMenu.path}/${item.path}`); }}
                                >
                                    <span style={{ whiteSpace: 'inheirt' }}>{item.label}</span>
                                </StyledMenuItemTeritary>
                            ))}
                        </Menu>
                    </>
                )}
                <TopLevelNavButton
                    // disableFocusRipple
                    to="learn"
                    aria-controls={(primaryOpen && selectedPrimaryMenu.path === 'learn') ? 'learn-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={primaryOpen ? 'true' : undefined}
                    onClick={(evt) => handlePrimaryClick(evt, 'learn')}
                >
                    {navigation?.learn?.label} {(primaryOpen && selectedPrimaryMenu.path === 'learn') ? <KeyboardArrowUpIcon style={{ color: Colors.yellow }} /> : <KeyboardArrowDownIcon style={{ color: 'white' }} />}
                </TopLevelNavButton>
                <TopLevelNavButton
                    // disableFocusRipple
                    to="support"
                    aria-controls={(primaryOpen && selectedPrimaryMenu.path === 'support') ? 'support-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={primaryOpen ? 'true' : undefined}
                    onClick={(evt) => handlePrimaryClick(evt, 'support')}
                >
                    {navigation?.support?.label} {(primaryOpen && selectedPrimaryMenu.path === 'support') ? <KeyboardArrowUpIcon style={{ color: Colors.yellow }} /> : <KeyboardArrowDownIcon style={{ color: 'white' }} />}
                </TopLevelNavButton>
                <TopLevelNavButton
                    // disableFocusRipple
                    to="about"
                    aria-controls={primaryOpen ? 'about-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={primaryOpen ? 'true' : undefined}
                    onClick={() => navigate('/about')}
                    style={{ paddingRight: 25 }}
                >
                    {navigation?.about?.label}
                </TopLevelNavButton>
                <LanguageSelectorDesktop />
            </NavDesktopContainer>
            <NavMobilContainer>
                <Box style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Box
                        style={{
                            height: 70,
                            borderBottom: `1px solid rgba(${Colors.purple3RGB}, 0.5)`,
                            width: '100%',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseIcon style={{ color: 'white', fontSize: 20 }} />
                        </IconButton>
                    </Box>
                    <StyledMobileButton
                        onClick={() => { navigate('/'); handleClose(); }}
                        style={{ color: 'white' }}
                    >
                        Home
                    </StyledMobileButton>

                    <StyledMobileButton
                        onClick={(evt) => handlePrimaryClick(evt, 'learn')}
                        className={primaryOpen && selectedPrimaryMenu?.path === 'learn' && 'mobile-primary-active'}
                    >
                        <span>
                            {navigation?.learn?.label}
                        </span>
                        <span>
                            {(primaryOpen && selectedPrimaryMenu?.path === 'learn') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </span>
                    </StyledMobileButton>
                    <Collapse
                        in={(primaryOpen && selectedPrimaryMenu?.path === 'learn')}
                        timeout="auto"
                        unmountOnExit
                        style={{ width: '100%' }}
                    >
                        <StyledMobileSecondaryWrapper>
                            {mobileSubNav()}
                        </StyledMobileSecondaryWrapper>
                    </Collapse>
                    <StyledMobileButton
                        onClick={(evt) => handlePrimaryClick(evt, 'support')}
                        className={primaryOpen && selectedPrimaryMenu?.path === 'support' && 'mobile-primary-active'}
                    >
                        {navigation?.support?.label} {(primaryOpen && selectedPrimaryMenu?.path === 'support') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </StyledMobileButton>
                    <Collapse
                        in={(primaryOpen && selectedPrimaryMenu?.path === 'support')}
                        timeout="auto"
                        unmountOnExit
                        style={{ width: '100%' }}
                    >
                        <StyledMobileSecondaryWrapper>
                            {mobileSubNav()}
                        </StyledMobileSecondaryWrapper>
                    </Collapse>
                    <StyledMobileButton
                        to="/about"
                        onClick={() => { navigate('/about'); handleClose(); }}
                    >
                        {navigation?.about?.label}
                    </StyledMobileButton>
                    <Box sx={{ pt: 5 }}>
                        <LanguageSelectorDesktop />
                    </Box>
                </Box>
            </NavMobilContainer>
        </nav>
    );
};

export default Navigation;
