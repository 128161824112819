import axios from 'axios';
import { format } from 'date-fns';

import AppTypes from '../action-types/app';
import { fetchModels } from './cms';

import form from '../utils/form-preprocessor';

const SubmitGeneralContactFormBegin = () => ({
    type: AppTypes.SUBMIT_CONTACTFORM_BEGIN,
});

const SubmitGeneralContactFormSuccess = () => ({
    type: AppTypes.SUBMIT_CONTACTFORM_SUCCESS,
});
const SubmitGeneralContactFormError = () => ({
    type: AppTypes.SUBMIT_CONTACTFORM_ERROR,
});

export const SubmitGeneralContactForm = ({ triggerSuccess, triggerFail, ...formData }) => async (dispatch) => {
    dispatch(SubmitGeneralContactFormBegin());

    const payload = {
        'first-name': formData.firstName,
        'last-name': formData.lastName,
        phone: formData.phone,
        diagnosis: formData.diagnosis,
    };

    if (formData.email) {
        payload.email = formData.email;
    }

    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_FORM_DIR}106/feedback`, form(payload), config);

        if (data.status === 'mail_sent') {
            triggerSuccess();
            dispatch(SubmitGeneralContactFormSuccess());
        } else {
            triggerFail();
            dispatch(SubmitGeneralContactFormError());
        }
    } catch (error) {
        dispatch(SubmitGeneralContactFormError());
    }
};

const SubmitStep6ContactFormBegin = () => ({
    type: AppTypes.SUBMIT_CONTACTFORM_BEGIN,
});

const SubmitStep6ContactFormSuccess = () => ({
    type: AppTypes.SUBMIT_CONTACTFORM_SUCCESS,
});
const SubmitStep6ContactFormError = () => ({
    type: AppTypes.SUBMIT_CONTACTFORM_ERROR,
});

export const SubmitStep6ContactForm = ({ triggerSuccess, triggerFail, ...formData }) => async (dispatch) => {
    dispatch(SubmitStep6ContactFormBegin());

    const payload = {
        'first-name': formData.firstName,
        'last-name': formData.lastName,
        phone: formData.phone,
        'case-number': formData.caseNumber,
        'upcoming-hearing': formData.hearing,
    };

    if (formData.email) {
        payload.email = formData.email;
    }

    if (formData.hearingDate) {
        // we want to display date as MM/DD/YYYY but send it to WP as YYYY-MM-DD
        payload['hearing-date'] = format(new Date(formData.hearingDate), 'yyyy-MM-dd');
    }

    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_FORM_DIR}105/feedback`, form(payload), config);

        if (data.status === 'mail_sent') {
            triggerSuccess();
            dispatch(SubmitStep6ContactFormSuccess());
        } else {
            triggerFail();
            dispatch(SubmitStep6ContactFormError());
        }
    } catch (error) {
        dispatch(SubmitStep6ContactFormError());
    }
};

const RegisterForCallbackBegin = () => ({
    type: AppTypes.REGISTER_CALLBACK_BEGIN,
});

const RegisterForCallbackSuccess = () => ({
    type: AppTypes.REGISTER_CALLBACK_SUCCESS,
});
const RegisterForCallbackError = () => ({
    type: AppTypes.REGISTER_CALLBACK_ERROR,
});

export const RegisterForCallback = ({ triggerSuccess, triggerFail, ...formData }) => async (dispatch) => {
    dispatch(RegisterForCallbackBegin());

    const payload = {
        'first-name': formData.firstName,
        'last-name': formData.lastName,
        phone: formData.phone,
    };

    // email is optional and sending it when null breaks WP
    if (formData.email) {
        payload.email = formData.email;
    }

    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_FORM_DIR}104/feedback`, form(payload), config);

        if (data.status === 'mail_sent') {
            triggerSuccess();
            dispatch(RegisterForCallbackSuccess());
        } else {
            triggerFail();
            dispatch(RegisterForCallbackError());
        }
    } catch (error) {
        triggerFail();
        dispatch(RegisterForCallbackError());
    }
};

export const OpenMobileNavigation = (payload = null) => ({
    type: AppTypes.OPEN_CLOSE_MOBILE_NAVIGATION,
    payload,
});

export const GetContentBegin = () => ({
    type: AppTypes.GET_CONTENT_BEGIN,
});

const GetLearnContentSuccess = () => ({
    type: AppTypes.GET_LEARN_CONTENT_SUCCESS,
});
const GetLearnContentError = () => ({
    type: AppTypes.GET_LEARN_CONTENT_ERROR,
});

const GetSupportContentSuccess = () => ({
    type: AppTypes.GET_SUPPORT_CONTENT_SUCCESS,
});
const GetSupportContentError = () => ({
    type: AppTypes.GET_SUPPORT_CONTENT_ERROR,
});

const GetGloassaryContentSuccess = () => ({
    type: AppTypes.GET_GLOSSARY_CONTENT_SUCCESS,
});
const GetGloassaryContentError = () => ({
    type: AppTypes.GET_GLOSSARY_CONTENT_ERROR,
});

export const GetContent = () => async (dispatch) => {
    dispatch(GetContentBegin());
    // SUPPORT
    try {
        dispatch(fetchModels('support-steps'));
        dispatch(GetSupportContentSuccess());
    } catch (error) {
        dispatch(GetSupportContentError());
    }

    // LEARN
    try {
        dispatch(fetchModels('learn'));
        dispatch(GetLearnContentSuccess());
    } catch (error) {
        dispatch(GetLearnContentError());
    }

    // GLOSSARY
    try {
        dispatch(fetchModels('glossary'));
        dispatch(GetGloassaryContentSuccess());
    } catch (error) {
        dispatch(GetGloassaryContentError());
    }
};
