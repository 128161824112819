import React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip } from 'react-tooltip';

import Colors from '../../styles/colors';

const StyledToolTip = styled(Tooltip)({
    maxWidth: 400,
    opacity: '1 !important',
    backgroundColor: Colors.purple2,
    width: '60%',

});

const TooltipWrapper = () => (
    <StyledToolTip
        id="tooltip"
        effect="solid"
        multiline
        arrowColor={Colors.purple2}
        style={{ backgroundColor: Colors.purple2 }}
    />
);

export default TooltipWrapper;
