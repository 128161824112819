import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

export default styled(Link)((props) => ({
    color: props.theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: '1.8rem',
    fontWeight: 600,
    display: 'block',
    marginBottom: 45,
    [props.theme.breakpoints.down('lg')]: {
        display: 'none',
    },
}));
