import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

import { getNavigation } from '../../../selectors/app';
import FooterLogo from '../../../images/bet-tzedek-logo-footer.png';

const FooterText = styled(Typography)(() => ({
    color: 'white',
    '& a': {
        color: 'white',
        textDecoration: 'none',
        fontWeight: 400,
    },
}));

const StyledLink = styled(Link)(() => ({
    color: 'white',
    display: 'block',
    paddingTop: 8,
    fontSize: '1.5rem',
    fontWeight: 400,
}));

const DesktopFooter = () => {
    const navigation = useSelector(getNavigation);
    const currentYear = new Date().getFullYear();
    const sectionNav = (section) => {
        const computedNav = section?.secondary?.map((item) => {
            let navLabel = item.label;
            // janky way of detecting if we are in the steps 1-6
            if (section.path === 'support' && !Number.isNaN(parseInt(item.path, 10))) navLabel = `${item.path}. ${item.label}`;
            return (
                <React.Fragment key={`${item.path}-footer`}>
                    <StyledLink
                        to={`${section.path}/${item.path}`}
                        component={RouterLink}
                    >
                        {navLabel}
                    </StyledLink>
                </React.Fragment>
            );
        });

        return computedNav;
    };

    return (
        <Grid container spacing={6} mt={4} pb={10} pl={0} display="flex">
            <Grid
                item
                container
                lg={3}
                alignContent="space-between"
                justifyContent="space-between"
            >
                <Grid item>
                    <img src={FooterLogo} alt="" style={{ width: 150 }} />
                    <FooterText variant="h4" pt={6}>Self-Help Conservatorship Clinic (SHCC)</FooterText>
                </Grid>
                <Grid item container justifyContent="space-between">
                    <Grid item>
                        <FooterText variant="body1" component="span" style={{ fontSize: '1.4rem' }}>&copy; {currentYear} BT SHCC</FooterText>
                    </Grid>
                    <Grid item>
                        <FooterText variant="body1" component="span" style={{ display: 'inline' }}>
                            <Link
                                href="https://bettzedek.org/privacy-policy/"
                                target="_blank"
                                rel="noopener"
                                style={{
                                    color: 'white',
                                    fontSize: '1.4rem',
                                    textDecoration: 'underline',
                                    fontWeight: 400,
                                }}
                            >
                                Privacy Policy &amp; Terms of Service
                            </Link>
                        </FooterText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={3}>
                <FooterText variant="h4" pb={2}>{navigation?.learn.label}</FooterText>
                {sectionNav(navigation?.learn)}
            </Grid>
            <Grid item lg={3}>
                <FooterText variant="h4" pb={2}>{navigation?.support.label}</FooterText>
                {sectionNav(navigation?.support)}
            </Grid>
            <Grid item lg={3}>
                <Box sx={{ pb: 12 }}>
                    <FooterText variant="h4" pb={2}>{navigation?.about.label}</FooterText>
                    <StyledLink
                        to="about"
                        component={RouterLink}
                    >
                        {navigation?.about.label}
                    </StyledLink>
                </Box>
                <Box>
                    <FooterText variant="h4" sx={{ pb: 2 }}>Contact</FooterText>
                    <FooterText variant="body1" pb={2}><MailOutlineIcon sx={{ mr: 1.6, fontSize: '20px' }} /><a href="mailto:cclinic@bettzedek.org" target="_blank" rel="noopener noreferrer">cclinic@bettzedek.org</a></FooterText>
                    <FooterText variant="body1"><PhoneOutlinedIcon sx={{ mr: 1.6, fontSize: '20px' }} /><a href="tel:3235495863">(323) 549-5863</a></FooterText>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DesktopFooter;
