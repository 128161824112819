import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
    NavLink,
    useMatch,
} from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import { getNavigation } from '../../../../selectors/app';

import { StyledSelect, StyledMobilMenuItem } from '../../../../components/inputs/styled-secondary-select';
import ToolsIcon from '../../../../images/icons/decision.png';
import ConervatorshipIcon from '../../../../images/icons/learn.png';
import RightsIcon from '../../../../images/icons/rights.png';
import GlossaryIcon from '../../../../images/icons/glossary.png';
import ResponsibilitiesIcon from '../../../../images/icons/responsibilities.svg';
import Colors from '../../../../styles/colors';

// DESKTOP NAV
const ButtonLinkSecondary = forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (
        <NavLink
            ref={ref}
            className={({ isActive }) => (
                isActive ? `${className} sideNavLearn sideNavActiveLearn` : `${className} sideNavLearn`
            )}
            {...otherProps}
        />
    );
});
ButtonLinkSecondary.propTypes = {
    className: PropTypes.string.isRequired,
};

const ButtonLinkTertiary = forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (
        <NavLink
            ref={ref}
            className={({ isActive }) => (
                isActive ? `${className} sideNavTertiaryLearn sideNavActiveTertiaryLearn` : `${className} sideNavTertiaryLearn`
            )}
            {...otherProps}
        />
    );
});
ButtonLinkTertiary.propTypes = {
    className: PropTypes.string.isRequired,
};

const StyledTopLevelButton = styled(Button)(() => ({
    fontWeight: 600,
    '&.active': {
        backgroundColor: 'white',
    },
}));
const TopLevelNavButton = (props) => {
    const { to, children } = props;
    const match = useMatch(`learn/${to}/*`);
    return (
        <StyledTopLevelButton className={match ? 'sideNavLearn sideNavActiveLearn' : 'sideNavLearn'} {...props}>{children}</StyledTopLevelButton>
    );
};
TopLevelNavButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};
// END DESKTOP NAV

const LearnSideNav = () => {
    const type = 'learn';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const navigation = useSelector(getNavigation);

    const location = useLocation();
    // used by both mobile and desktop
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        const splitURL = location?.pathname.toString().split('/');
        splitURL.splice(0, 2); // array contains unneeded item from start of array
        const mergedArray = splitURL.join('/');
        setSelectedValue(mergedArray);
    }, [location]);

    const navigate = useNavigate();
    const handleMobileNavChange = (evt) => {
        navigate(evt.target.value);
    };

    const [selectiveNav, setSelectiveNav] = useState([]);
    useEffect(() => {
        setSelectiveNav(navigation[type].secondary.slice(1));
    }, [navigation]);

    const desktopNav = () => {
        const computedNav = selectiveNav.map((item) => (
            <React.Fragment key={`${item.path}-sidenav-desktop`}>
                {!item?.tertiary && (
                    <Button
                        to={`/learn/${item.path}`}
                        component={ButtonLinkSecondary}
                    >
                        {item.path === 'glossary' && (
                            <img src={GlossaryIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                        )}
                        {item.path === 'rights' && (
                            <img src={RightsIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                        )}
                        {item.path === 'responsibilities' && (
                            <img src={ResponsibilitiesIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                        )}
                        {item.label}
                    </Button>
                )}
                {item?.tertiary && (
                    <TopLevelNavButton
                        to={item.path}
                        component={ButtonLinkSecondary}
                    >
                        {item.path === 'decision' && (
                            <img src={ToolsIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                        )}
                        {item.path === 'conservatorship' && (
                            <img src={ConervatorshipIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                        )}
                        {item.label}
                    </TopLevelNavButton>
                )}
                {item?.tertiary && (
                    <Collapse in={selectedValue.includes(item.path)} timeout="auto" unmountOnExit>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderLeft: `2px solid rgba(${Colors.purple1RGB}, 0.5)`,
                                marginLeft: 25,
                                marginTop: 10,
                                marginBottom: 15,
                            }}
                        >
                            {item?.tertiary?.map((tertiaryItem) => (
                                <Button
                                    key={`${item.path}-${tertiaryItem.path}`}
                                    to={`${item.path}/${tertiaryItem.path}`}
                                    component={ButtonLinkTertiary}
                                >
                                    {tertiaryItem.label}
                                </Button>
                            ))}
                        </Box>
                    </Collapse>
                )}
            </React.Fragment>
        ));
        return computedNav;
    };
    const mobileNav = () => {
        const navItems = selectiveNav.map((item) => (
            <StyledMobilMenuItem
                key={item.path}
                value={item.path}
            >
                {item.path === 'glossary' && (
                    <img src={GlossaryIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                )}
                {item.path === 'rights' && (
                    <img src={RightsIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                )}
                {item.path === 'decision' && (
                    <img src={ToolsIcon} alt="" style={{ height: 30, marginRight: 10 }} />
                )}
                {item.path === 'conservatorship' && (
                    <img src={ConervatorshipIcon} alt="" style={{ height: 25, marginRight: 10 }} />
                )}
                {item.path === 'responsibilities' && (
                    <img src={ResponsibilitiesIcon} alt="" style={{ height: 25, marginRight: 10 }} />
                )}
                {item.label}
            </StyledMobilMenuItem>
        ));

        return (
            <div style={{ textAlign: 'left', width: '100%' }}>
                <FormLabel
                    style={{
                        textTransform: 'none',
                        fontSize: '1.6rem',
                        display: 'block',
                        fontWeight: '700',
                        color: 'black',
                    }}
                >
                    Learn about:
                </FormLabel>
                <StyledSelect
                    value={selectedValue}
                    onChange={handleMobileNavChange}
                    renderValue={(value) => `${selectiveNav.find((item) => value.includes(item.path))?.label}`}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                borderRadius: 12,
                            },
                        },
                    }}
                >
                    {navItems}
                </StyledSelect>
            </div>
        );
    };

    // MAIN WRAPPER
    return (
        <Grid container alignItems="center" height="100%" style={{ flexGrow: 1 }} component="section">
            <Grid
                item
                sx={{
                    width: isMobile ? 'fit-content' : 315,
                    display: 'flex',
                    flexGrow: 1,
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: { sm: 'center', lg: 'start' },
                    pr: { xs: 0, lg: 8 },
                }}
            >
                {!isMobile && desktopNav()}
                {isMobile && mobileNav()}
            </Grid>
        </Grid>
    );
};

export default LearnSideNav;
