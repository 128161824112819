const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    OPEN_CLOSE_MOBILE_NAVIGATION: true,
    GET_CONTENT_BEGIN: true,

    GET_GLOSSARY_CONTENT_SUCCESS: true,
    GET_GLOSSARY_CONTENT_ERROR: true,

    GET_LEARN_CONTENT_SUCCESS: true,
    GET_LEARN_CONTENT_ERROR: true,

    GET_SUPPORT_CONTENT_SUCCESS: true,
    GET_SUPPORT_CONTENT_ERROR: true,

    REGISTER_CALLBACK_BEGIN: true,
    REGISTER_CALLBACK_SUCCESS: true,
    REGISTER_CALLBACK_ERROR: true,

    SUBMIT_CONTACTFORM_BEGIN: true,
    SUBMIT_CONTACTFORM_SUCCESS: true,
    SUBMIT_CONTACTFORM_ERROR: true,
});
