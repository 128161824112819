import React, { lazy } from 'react';
import {
    Routes,
    Route,
    Link as RouterLink,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';

import SideNav from './components/side-nav';

const Glossary = lazy(() => import('../../routes/learn/glossary'));
const OverviewPage = lazy(() => import('../../routes/learn/components/overview-page'));
const ConservatorshipOverview = lazy(() => import('../../routes/learn/what-is-conservatorship'));
const ConservatorshipGeneral = lazy(() => import('../../routes/learn/what-is-conservatorship/general-conservatorships'));
const ConservatorshipLimited = lazy(() => import('../../routes/learn/what-is-conservatorship/limited-conservatorship'));
const ConservatorshipMental = lazy(() => import('../../routes/learn/what-is-conservatorship/mental-health-conservatorships'));
const DescisionOverview = lazy(() => import('../../routes/learn/descision-making-tools'));
const DecisionPower = lazy(() => import('../../routes/learn/descision-making-tools/power-of-attorney'));
const DecisionDurable = lazy(() => import('../../routes/learn/descision-making-tools/durable-power-of-attorney'));
const DecisionSupported = lazy(() => import('../../routes/learn/descision-making-tools/supported-decision-making'));
const DecisionEducation = lazy(() => import('../../routes/learn/descision-making-tools/education-authorization'));
const DecisionAuthorized = lazy(() => import('../../routes/learn/descision-making-tools/authorized-represenative'));
const ConservateeRights = lazy(() => import('../../routes/learn/rights-of-conservatee'));
const ConservatorRespnsibilities = lazy(() => import('../../routes/learn/responsibilities-of-conservator'));

const StyledLink = styled(Link)((props) => ({
    color: props.theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: '1.8rem',
    fontWeight: 600,
    display: 'block',
    marginBottom: 45,
    [props.theme.breakpoints.down('lg')]: {
        display: 'none',
    },
}));

const SideNavLayout = () => (
    <Container>
        <Grid item container pt={6} pb={8}>
            <Grid item xs={12} md="auto">
                <StyledLink to="../overview" component={RouterLink}>Back to Overview</StyledLink>
                <SideNav />
            </Grid>
            <Grid item pt={{ xs: 4, lg: 10 }} style={{ flex: 1 }}>
                <Routes>
                    {/* Learn */}
                    <Route path="overview" element={<OverviewPage />} />
                    <Route path="rights" element={<ConservateeRights />} />
                    <Route path="responsibilities" element={<ConservatorRespnsibilities />} />
                    <Route path="conservatorship">
                        <Route
                            index
                            element={(
                                <ConservatorshipOverview />
                            )}
                        />
                        <Route path="general" element={<ConservatorshipGeneral />} />
                        <Route path="limited" element={<ConservatorshipLimited />} />
                        <Route path="mental" element={<ConservatorshipMental />} />
                    </Route>
                    <Route path="decision">
                        <Route
                            index
                            element={(
                                <DescisionOverview />
                            )}
                        />
                        <Route path="power" element={<DecisionPower />} />
                        <Route path="durable" element={<DecisionDurable />} />
                        <Route path="education" element={<DecisionEducation />} />
                        <Route path="supported" element={<DecisionSupported />} />
                        <Route path="authorized" element={<DecisionAuthorized />} />
                    </Route>
                    <Route path="glossary" element={<Glossary />} />
                </Routes>
            </Grid>
        </Grid>
    </Container>
);

export default withRoot(SideNavLayout, themer);
