import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const PrimaryButton = ({
    children,
    disabled,
    ...props
}) => (
    <Button
        variant="contained"
        color="primary"
        aria-disabled={disabled}
        disabled={disabled} // this will disable the snackbar functionality
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        disableElevation
        // disableFocusRipple
        disableRipple
    >
        { children }
    </Button>
);

PrimaryButton.defaultProps = {
    children: null,
    disabled: false,
};

PrimaryButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

export default PrimaryButton;
