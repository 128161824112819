import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import MobileFooter from './components/mobile';
import DesktopFooter from './components/desktop';

const StyledWrapper = styled(Box)((props) => ({
    background: `linear-gradient(270deg, #773BEE 0%, ${props.theme.palette.primary.main} 100%)`,
    flexShrink: 0,
}));

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <StyledWrapper component="footer">
            <Container>
                {isMobile && <MobileFooter />}
                {!isMobile && <DesktopFooter />}
            </Container>
        </StyledWrapper>
    );
};

export default Footer;
